import React from 'react'

function Usernotfunctional() {
  return (
    <div className='user' > 
        <i className="fa-solid fa-user"></i>
    </div>
  )
}

export default Usernotfunctional